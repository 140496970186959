export {
  H1_TextBanner,
  H1_TextHeadline,
  H1_TextLargeHeadline,
  H1_TextMidHeadline,
  H1_TextMiddle,
  H1_TextSmall,
  H1_TextSubBanner,
  H1_TextSubtitle,
  H1_TextXs,
  H1_TextSizes,
  H1_TextNames
} from "./base-typography";
