/* eslint react/jsx-pascal-case: 0 */
/* eslint @typescript-eslint/naming-convention: 0 */
import React, { ReactNode, useState, useEffect, ForwardedRef } from "react";
import styled, { keyframes } from "styled-components";
import { ButtonSize, ButtonType } from "./types";
import { H1_TextSmall } from "../typography";

interface InnerButtonProps {
  disabled?: boolean;
  $loading?: boolean;
  icon?: ReactNode;
  $width?: string;
  size?: ButtonSize;
  type?: ButtonType;
  $onlyIcon?: boolean;
}

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.i<{ $loading: boolean }>`
  transition: width 0.5s ease-in-out;
  animation: ${rotate} 1s linear infinite;
  animation-play-state: ${({ $loading }) => ($loading ? "running" : "paused")};
  &&,
  &.loading-icon-removed {
    width: 0;
  }
  &&.loading-icon {
    width: 16px;
  }
`;

const handleSize = (size: ButtonSize) => {
  switch (size) {
    case "small":
      return "24px";
    default:
      return "38px";
  }
};

const handleColor = ({ type, disabled }: InnerButtonProps) => {
  switch (type) {
    case "outline":
      return disabled ? "#BFBFBF" : "#5A5AFF";
    case "outline-negative":
      return disabled ? "#BFBFBF" : "#FFFFFF";
    case "secondary":
      return disabled ? "#BFBFBF" : "#000000";
    case "link":
      return disabled ? "#BFBFBF" : "#000000";
    case "upgrade":
      return disabled ? "#D9D9D9" : "#FF9000";
    case "dark":
      return disabled ? "#D9D9D9" : "#FFFFFF";
    default:
      return disabled ? "#BFBFBF" : "#FFFFFF";
  }
};

const handlePadding = (props: InnerButtonProps) => {
  if (props.$onlyIcon && props.icon) return "0.5rem";
  if (props.type === "link") {
    return "0";
  }
  if (!props.icon) return "1rem";
  if (props.icon && !props.$onlyIcon) return "1.375rem";
  return "0";
};

const handleBorderColor = ({ type, disabled }: InnerButtonProps) => {
  switch (type) {
    case "outline":
      return disabled ? "#BFBFBF" : "#5A5AFF";
    case "outline-negative":
      return disabled ? "#BFBFBF" : "#FFFFFF";
    case "secondary":
      return "transparent";
    case "link":
      return "transparent";
    default:
      return "transparent";
  }
};

const handleBackgroundColor = ({ type, disabled }: InnerButtonProps) => {
  switch (type) {
    case "outline":
      return "transparent";
    case "outline-negative":
      return "transparent";
    case "secondary":
      return disabled ? "#D9D9D9" : "#FFFFFF";
    case "link":
      return "transparent";
    case "upgrade":
      return disabled ? "#BFBFBF" : "#FFE7CA";
    case "dark":
      return "#BFBFBF";
    default:
      return disabled ? "#D9D9D9" : "#5A5AFF";
  }
};

const handleHoverBackgroundColor = ({ type }: InnerButtonProps) => {
  // https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
  switch (type) {
    case "outline":
      return "#5A5AFF";
    case "outline-negative":
      return "transparent";
    case "secondary":
      return "#F5F5F5";
    case "link":
      return "transparent";
    case "upgrade":
      return `#FFE7CA99`; // 60% opacity
    case "dark":
      return `#FFFFFF99`; // 60% opacity
    default:
      return "#AAAAFF";
  }
};

const handleHoverBorderColor = ({ type }: InnerButtonProps) => {
  switch (type) {
    case "outline":
      return "transparent";
    case "outline-negative":
      return "#5A5AFF";
    case "secondary":
      return "transparent";
    case "link":
      return "transparent";
    default:
      return "transparent";
  }
};

const handleActiveBorderColor = ({ type }: InnerButtonProps) => {
  switch (type) {
    case "outline":
      return "#AAAAFF";
    case "outline-negative":
      return "#AAAAFF";
    case "secondary":
      return "transparent";
    case "link":
      return "transparent";
    default:
      return "transparent";
  }
};

const handleActiveColor = ({ type }: InnerButtonProps) => {
  switch (type) {
    case "outline":
      return "#AAAAFF";
    case "outline-negative":
      return "#AAAAFF";
    case "secondary":
      return "#000000";
    case "link":
      return "#AAAAFF";
    case "upgrade":
      return "#FF9000";
    case "dark":
      return "#595959";
    default:
      return "#FFFFFF";
  }
};

const handleHoverColor = ({ type }: InnerButtonProps) => {
  switch (type) {
    case "outline":
      return "#FFFFFF";
    case "outline-negative":
      return "#5A5AFF";
    case "secondary":
      return "#000000";
    case "link":
      return "#5A5AFF";
    case "upgrade":
      return "#FF9000";
    case "dark":
      return "#595959";
    default:
      return "#FFFFFF";
  }
};

const handleActiveBackgroundColor = ({ type }: InnerButtonProps) => {
  switch (type) {
    case "outline":
      return "#DCDDFF";
    case "outline-negative":
      return "transparent";
    case "secondary":
      return "#F0F0F0";
    case "link":
      return "transparent";
    case "upgrade":
      return "#FFE7CA";
    case "dark":
      return "#FFFFFF";
    default:
      return "#DCDDFF";
  }
};

const StyledButton = styled.button<{
  disabled?: boolean;
  $loading?: boolean;
  icon?: ReactNode;
  $width?: string;
  size?: ButtonSize;
  type?: ButtonType;
  $onlyIcon?: boolean;
}>`
  cursor: ${(props) => (props.disabled || props.$loading ? "disabled" : "pointer")};
  gap: ${(props) => (!!props.icon || !!props.$loading ? "8px" : "0")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  padding-left: 8px;
  padding-right: 8px;
  appearance: button;
  border-radius: 2px;
  user-select: none;
  border-width: 1px;
  border-color: ${({ type, disabled }) => handleBorderColor({ type, disabled })};
  background-color: ${(props) => handleBackgroundColor(props)};
  height: ${(props) => handleSize(props.size as ButtonSize)};
  color: ${(props) => handleColor(props)};
  span {
    color: ${(props) => handleColor(props)};
  }
  fill: ${(props) => handleColor(props)};
  padding-left: ${(props) => handlePadding(props)};
  padding-right: ${(props) => handlePadding(props)};
  width: ${(props) => props.$width};
  transition: all 0.3s ease-in-out;
  pointer-events: ${(props) => (props.$loading || props.disabled) && "none"};
  .fa-spinner-third {
    animation-duration: 1s;
  }
  i {
    font-size: 16px;
  }
  svg,
  img {
    width: 16px;
    height: 16px;
  }
  &:hover {
    background-color: ${(props) => handleHoverBackgroundColor(props)};
    border-color: ${(props) => handleHoverBorderColor(props)};
    color: ${(props) => handleHoverColor(props)};
    span {
      color: ${(props) => handleHoverColor(props)};
    }
    fill: ${(props) => handleHoverColor(props)};
    i,
    svg {
      color: ${(props) => handleHoverColor(props)};
      fill: ${(props) => handleHoverColor(props)};
    }
  }
  &:active {
    background-color: ${(props) => handleActiveBackgroundColor(props)};
    border-color: ${(props) => handleActiveBorderColor(props)};
    color: ${(props) => handleActiveColor(props)};
    span {
      color: ${(props) => handleActiveColor(props)};
    }
    fill: ${(props) => handleActiveColor(props)};
    i,
    svg {
      color: ${(props) => handleActiveColor(props)};
      fill: ${(props) => handleActiveColor(props)};
    }
  }
`;

export type H1_ButtonProps = {
  size?: ButtonSize;
  type?: ButtonType;
  className?: string;
  width?: string;
  loading?: boolean;
  disabled?: boolean;
  onlyIcon?: boolean;
  icon?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: ReactNode;
  dataAutoId?: string;
};

export const H1_Button = React.forwardRef(
  (
    {
      className = "",
      width,
      size,
      type,
      disabled,
      icon,
      onlyIcon,
      loading,
      onClick,
      children,
      dataAutoId
    }: H1_ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const [loadingClass, setLoadingClass] = useState<string>("");
    useEffect(() => {
      if (loading) {
        setTimeout(() => {
          // Let the component render so the width animation can be seen
          setLoadingClass("loading-icon");
        });
      }
    }, [loading]);

    useEffect(() => {
      if (loading === false && loadingClass === "loading-icon") {
        setLoadingClass("loading-icon-removed");
        setTimeout(() => {
          setLoadingClass("");
        }, 500);
      }
    }, [loadingClass, loading]);

    return (
      <StyledButton
        className={className}
        $loading={loading}
        disabled={disabled}
        // @ts-ignore - should change the property name as it an existing property on a button
        type={type}
        size={size}
        onClick={onClick}
        $width={width}
        ref={ref}
        icon={icon}
        $onlyIcon={!!onlyIcon}
        data-auto-id={dataAutoId}
      >
        {icon && !loading && icon}
        {!!loadingClass && (
          <StyledSpinner
            $loading={!!loading}
            className={`fa-regular fa-spinner-third fa-spin ${loadingClass}`}
          />
        )}
        {!onlyIcon && <H1_TextSmall>{children}</H1_TextSmall>}
      </StyledButton>
    );
  }
);
H1_Button.displayName = "H1_Button";
H1_Button.defaultProps = {
  size: "default",
  type: "primary",
  loading: false,
  icon: undefined,
  children: undefined,
  dataAutoId: undefined
};
