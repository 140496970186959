import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import Image from 'next/image';

const StyledTopBar = styled.div`
    display: flex;
    flex: 1;
    justify-content: start;
    width: 100%;
    max-height: 50px;
    padding: 10px 10px 10px calc(50% - 48px);
    ${up('md')} {
        padding: 10px 10px 10px calc(40px);
    }
    img {
        cursor: pointer;
    }
`;


const TopBar = ({requestId}: {requestId?: string}) => {
    return (
        <StyledTopBar>
            <Image
                src="/logo.svg" 
                alt="Hour One Logo"
                width={96}
                height={43}
                onClick={() => window.location.replace(`https://app.hourone.ai?init=signUp&utm_source=hourone&utm_medium=video${!!requestId? "utm_term=" + requestId: ''}`)}
            />
        </StyledTopBar>
    );
}

export default TopBar;