import React, { useState } from 'react';

import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import Error from 'next/error'
import styled, { keyframes } from 'styled-components';
import { useTranslations } from 'next-intl';
import { up } from 'styled-breakpoints';
import { bpTheme } from '../styles/theme';
import PasswordProtectionDialog from '../components/PasswordProtectionDialog';
import TopBar from '../components/TopBar';
import useSWR from 'swr';
import { encodeImageToBlurhash } from '../lib/encoders';
import { SegmentContextProviderProps } from "../components/core/segment-context";
import { H1PlayerAnalytics } from "../components/core/h1-player-analytics";
import { H1Loader } from "../components/core/h1-loader";
import Image from 'next/image';

const ComponentWithNoSSR = dynamic<SegmentContextProviderProps>(
  async () =>
    await import(
      '../components/core/segment-context'
      ).then((comp) => comp.SegmentContextProvider),
  { ssr: false }
) as React.ElementType;

const gradientAnimation = keyframes`
    0% {
        transform: translateX(-20px) rotate(10deg);
    }
    50% {
        transform: translateX(200px) rotate(10deg);
    }
    100% {
        transform: translateX(200px) rotate(10deg);
    }
`;

const PaddingBody = styled.div`
    padding: 20px;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
`;

const BottomBar = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${up('md')} {
        flex-direction: row;
    }
`;

const BottomFixedBar = styled.div<{ $show: boolean }>`
    display: ${(props) => props.$show ? 'flex' : 'none'};
    position: fixed;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: row;
    padding-top: 10px;
    background-color: ${props => props.theme.palette.common.white};
    gap: 10px;

    ${up('md')} {
        padding: 10px;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const BottomText = styled.p`
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: ${props => props.theme.palette.common.purple};
    flex: 1;
    display: flex;
    align-items: center;

    ${up('md')} {
        width: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const Text = styled.span`
    color: #6c6c6c;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, Poppins, Ariel;;
    font-size: 16px;
    font-weight: 500;
`;

const PrimaryButton = styled.button`
    background-color: ${props => props.theme.palette.common.purple};
    color: ${props => props.theme.palette.common.white};
    background-size: 200% auto;
    text-decoration: none;
    border-radius: 2px;
    font-size: 1.4rem;
    line-height: 2.2rem;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, Poppins, Ariel;
    width: 180px;
    align-self: center;
    position: relative;

    ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 100%;
        background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0.5) 0%,
                rgba(255, 255, 255, 0.3) 50%,
                rgba(255, 255, 255, 0) 100%
        );
        transform: rotate(-30deg);
        animation: ${gradientAnimation} 3s linear infinite;
    }
`;

const SeconderyButton = styled.button`
    background-color: transparent;
    color: ${props => props.theme.palette.common.purple};
    font-size: 1.4rem;
    line-height: 2.2rem;
    padding: 8px;

    ${up('md')} {
        width: 180px;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    ${up('md')} {
        flex-direction: row;
    }

    & button {
        flex: 1;
    }
`;

const PlayerContainer = styled.div`
    max-width: ${bpTheme['styled-breakpoints'].breakpoints.lg};
    width: 100%;
    border-radius: 2px;
    overflow: hidden;

    & > div {
        display: block;
        margin: auto;
        width: 100%;
        max-height: calc(100% - 70px);
        border-radius: 2px;
        overflow: hidden;
    }

    mux-player {
        border-radius: 2px;
        overflow: hidden;

        ::part(center), [part~='center'] {
            --media-icon-color: white;
            background-color: #5A5AFF;
            border-radius: 50%;
            padding: 10px;
        }

        video,
        ::slotted([slot=media]),
        ::slotted([slot=poster]),
        media-control-bar {
            border-radius: 2px;
        }
    }
`;

const ErrorContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
`;

const LoaderContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface VideoForPlayerResponse {
  playback_id: string;
  title: string;
  created_at: Date;
  created_by_picture?: string;
  created_by_name?: string;
  tags?: string[];
  reactions?: string[];
  remixed: number;
  views?: number;
  viewers?: number;
  analytics_updated_at?: Date;
  watch_time?: number;
}

interface VideoData extends VideoForPlayerResponse {
  sourceWidth: number;
  sourceHeight: number;
  blurHashBase64?: string;
}

interface PlayerProps {
  requestId: string;
  messages: Record<string, string>;
  url: string;
}

const blurhash = async (playbackId: string) => {
  const url = `https://image.mux.com/${playbackId}/thumbnail.png?width=400&height=200&fit_mode=smartcrop&time=0`;
  const blurhash = await encodeImageToBlurhash(url);
  return blurhash;
};

const fetcher = async (resource, init) => {
  const headers = new Headers({});
  if (init && init.authorization) {
    const encrypt = Buffer.from(init.authorization).toString('base64');
    headers.append('Authorization', `Basic ${encrypt}`);
  }
  return fetch(resource, { headers }).then(async (res) => {
    const resJson = await res.json();
    if (!res.ok) {
      return {
        data: resJson,
        code: res.status
      };
    } else {
      const blured = await blurhash(resJson.playback_id);
      return {
        data: { ...resJson, blurHashBase64: blured },
        code: res.status
      };
    }
  });
};
const Player = ({ requestId, url }: PlayerProps) => {
  const [password, setPassword] = useState<string>();
  const {
    data,
    error: swrErr,
    isLoading
  } = useSWR([url, password], ([url, password]) => fetcher(url, password && { authorization: `${requestId}:${password}` }));

  const messages = useTranslations('Player');
  const errorCodeToHandleInPage = [401, 403];

  const isPassword = errorCodeToHandleInPage.includes(data?.code || 500);
  const isErr = swrErr || data?.code && (data?.code > 299 || data?.code < 199);
  if (isPassword) {
    return <PasswordProtectionDialog onSubmit={setPassword} isLoading={isLoading} />;
  }
  if (isErr) {


    return (
      <ErrorContainer>
        <Image
          src="/logo.svg"
          alt="Hour One Logo"
          width={96}
          height={43}
          onClick={() => window.location.replace(`https://app.hourone.ai?init=signUp&utm_source=hourone&utm_medium=video${!!requestId ? 'utm_term=' + requestId : ''}`)}
        />
        <div>Video is not available</div>
      </ErrorContainer>
    );
  }
  if (!!data) {
    return (
      <>
        <NextSeo
          title="HourOne Player"
          description="Made by Hour One"
          canonical={`://${url}`}
          openGraph={(requestId.length > 0) ? {
            url: `://${process.env.NEXT_PUBLIC_VIDEO_PORTAL_OG_URL as string}?id=${requestId}&env=${process.env.NEXT_PUBLIC_VERCEL_ENV as string}`,
            title: data.data.title,
            description: `Made by Hour One`,
            images: [
              {
                url: `https://image.mux.com/${data.data.playback_id}/thumbnail.png?width=530&height=1200&fit_mode=smartcrop&time=0`,
                width: 1200,
                height: 630,
                alt: `${data.data.title} preview`,
                type: 'image/jpeg'
              }
            ],
            site_name: 'HourOne Player'
          } : undefined}
        />
        <PaddingBody>
          <TopBar requestId={requestId} />
          <ComponentWithNoSSR writeKey={process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY as string}>
            <PlayerContainer>
              <H1PlayerAnalytics
                trackEventsProps={{ requestId }}
                lazyLoad
                thumbnailTime={0}
                metadata={{
                  // eslint-disable-next-line camelcase
                  env_key: process.env.NEXT_PUBLIC_MUX_ENV_VAR as string,
                  // eslint-disable-next-line camelcase
                  video_id: requestId,
                  // eslint-disable-next-line camelcase
                  video_title: (data.data as VideoData).title,
                  // eslint-disable-next-line camelcase
                  player_name: 'player.hourone.ai'
                }}
                placeholder={(data.data as VideoData).blurHashBase64}
                muxEnvKey={process.env.NEXT_PUBLIC_MUX_ENV_VAR as string}
                playbackId={(data.data as VideoData).playback_id}
              />
            </PlayerContainer>
          </ComponentWithNoSSR>
          <BottomBar>
            <Text>{messages('subtext')}</Text>
            <PrimaryButton
              onClick={() => window.location.replace(`https://app.hourone.ai?init=signUp&utm_source=hourone&utm_medium=video&utm_term=${requestId}`)}
            >
              {messages('actionButton')}
            </PrimaryButton>
          </BottomBar>
          <BottomFixedBar $show={false}>
            <BottomText>
              {messages('shouldLogin')}
            </BottomText>
            <ButtonsWrapper>
              <PrimaryButton>
                {messages('PrimaryButtonLabel')}
              </PrimaryButton>
              <SeconderyButton>
                {messages('SeconderyButtonLabel')}
              </SeconderyButton>
            </ButtonsWrapper>
          </BottomFixedBar>
        </PaddingBody>
      </>
    );
  }

  if (isLoading) {
    return (
      <PaddingBody>
        <TopBar requestId={requestId} />
        <LoaderContainer>
          <H1Loader size="500px" />
        </LoaderContainer>
      </PaddingBody>
    );
  }

  return <></>;
};

export default Player;

export const getServerSideProps = async (ctx: { params: { reqId: any; }; locale: string; }): Promise<{
  props: PlayerProps
} | null> => {
  if (ctx.params == null) {
    return null;
  }
  const locale = ctx.locale;
  const requestId = ctx.params.reqId;
  const msgs = (await import(`../messages/${locale}.json`)).default;
  const url = `${process.env.REALS_SERVER as string}/v2/player/${requestId as string}`;
  return { props: { requestId, messages: msgs, url } };

};
