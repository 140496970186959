import { useTranslations } from 'next-intl';
import { FormEvent } from 'react';
import styled from 'styled-components';
import TopBar from './TopBar';
import { H1_Button } from "../components/button";
import { H1_Input } from "../components/input";
import { H1_TextLargeHeadline } from "../components/typography";

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 10px;
`;

const StyledButton = styled(H1_Button)`
  border-radius: 4px;
  background-color: ${props => props.theme.palette.common.purple};
  color: white;
  max-height: 30px;
  span{
    font-size: 14px;
    font-weight: 400;
  }
`;

const StyledFormContainer = styled.div`
    padding: 20px;
    flex: 1;
    gap: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    input {
      border-radius: 4px;
      border: 1px solid black;
      height: 34px;
      font-size: 14px;
    }
`;

const PaddingBody = styled.div`
    padding: 20px;
    min-height: calc(100vh - 50px);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    gap: 20px; 
`;


interface PasswordProtectionDialogProps {
  isLoading: boolean;
  onSubmit: (password: string) => void;
}


const PasswordProtectionDialog: React.FC<PasswordProtectionDialogProps> = ({ isLoading, onSubmit }) => {
  const messages = useTranslations('PasswordProtections');

  const submitPass= (event: FormEvent) => {
    event.preventDefault();
    const password = event.target[0].value
    if( password.length && !isLoading){
      onSubmit(password);
    }
  }

  return (
    <PaddingBody>
        <TopBar />
        <StyledFormContainer >
          <H1_TextLargeHeadline>
            {messages("title")}
          </H1_TextLargeHeadline>
          <StyledForm onSubmit={submitPass}>
            {/* @ts-ignore */}
            <H1_Input type="password" id="pass" name="password" required autoFocus size='large'/>
            {/* @ts-ignore */}
            <StyledButton type="submit"> {messages("buttonText")}</StyledButton>
          </StyledForm>
        </StyledFormContainer>    
      </PaddingBody>
  )  
};

export default PasswordProtectionDialog;
