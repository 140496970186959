import React from 'react';
import Lottie from "lottie-react";
import styled from "styled-components";
import animationData from "./lottie-loader.json";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const StyledLottie = styled(Lottie)`
  height: ${(props: { sizeprop: string }) => props.sizeprop};
  width: ${(props: { sizeprop: string }) => props.sizeprop};
`;

export type H1LoaderProps = {
  /**
   * a string of loader size (ex. 10px).
   */
  size?: string;
};

export function H1Loader({ size }: H1LoaderProps) {
  return (
    <Container>
      <StyledLottie sizeprop={size || "100%"} loop autoplay animationData={animationData} />
    </Container>
  );
}